<template>
	<div>
		<div class="row my-4">
			<h1 class="col-sm-12 text-right">Fideicomisos</h1>
		</div>

		<div class="d-flex flex-wrap">
			<div v-if="$auth.can('administrador_de_garantias','listar_fideicomisos')" class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/fideicomiso.png" alt="Notarios" width="80px" />
						</div>
						<h4 class="card-title">Fideicomisos</h4>
						<p class="card-subtitle text-muted">Administracion de fideicomisos</p>
						<router-link :to="{name: 'garantias_fideicomisos_fideicomisos',params:[{tipo: 1}]}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
			<div v-if="$auth.can('administrador_de_garantias','listar_fideicomisos')" class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/fideicomiso.png" alt="Notarios" width="80px" />
						</div>
						<h4 class="card-title">Convenios</h4>
						<p class="card-subtitle text-muted">Administracion de Convenios</p>
						<router-link :to="{name: 'garantias_fideicomisos_fideicomisos_convenios',params:[{tipo: 0}]}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
			<div v-if="$auth.can('administrador_de_garantias','listar_patrimonios')" class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/patrimonio.png" alt="Notarios" width="80px" />
						</div>
						<h4 class="card-title">Patrimonios</h4>
						<p class="card-subtitle text-muted">Administracion de Patrimonios</p>
						<router-link :to="{name: 'garantias_fideicomisos_patrimonios'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
			<div v-if="$auth.can('administrador_de_garantias','listar_usuarios')" class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/partes.png" alt="Notarios" width="80px" />
						</div>
						<h4 class="card-title">Clientes</h4>
						<p class="card-subtitle text-muted">Administracion de Clientes</p>
						<router-link :to="{name: 'garantias_fideicomisos_clientes'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
		</div>

		<div class="row my-4">
			<h1 class="col-sm-12 text-right">Activos no productivos</h1>
		</div>

		<div class="d-flex flex-wrap">
			<div class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/patrimonio.png" alt="Notarios" width="80px" />
						</div>
						<h4 class="card-title">Garantias para venta</h4>
						<p class="card-subtitle text-muted">Administrador de garantias para venta</p>
						<router-link :to="{name: 'garantias_activos_garantias'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
		</div>

		<div class="row my-4">
			<h1 class="col-sm-12 text-right">Otros</h1>
		</div>

		<div class="d-flex flex-wrap">
			<div class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/patrimonio.png" alt="Notarios" width="80px" />
						</div>
						<h4 class="card-title">Catálogos</h4>
						<p class="card-subtitle text-muted">Administrador de catálogos</p>
						<router-link :to="{name: 'garantias_otros_catalogos'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
			
			<div class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/patrimonio.png" alt="Notarios" width="80px" />
						</div>
						<h4 class="card-title">Layouts</h4>
						<p class="card-subtitle text-muted">Carga de layout</p>
						<router-link :to="{name: 'garantias_layout'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		mounted: function() {
			let menu = [];
			let fideicomisos = {title: 'Fideicomisos'};

			if (this.$auth.can('administrador_de_garantias', 'listar_fideicomisos'))
				fideicomisos['garantias_fideicomisos_fideicomisos'] = ['Fideicomisos',{tipo: 1}];

			if (this.$auth.can('administrador_de_garantias', 'listar_fideicomisos'))
				fideicomisos['garantias_fideicomisos_fideicomisos_convenios'] = ['Convenios',{tipo: 0}];

			if (this.$auth.can('administrador_de_garantias', 'listar_patrimonios'))
				fideicomisos['garantias_fideicomisos_patrimonios'] = 'Patrimonios';

			if (this.$auth.can('administrador_de_garantias', 'listar_usuarios'))
				fideicomisos['garantias_fideicomisos_clientes'] = 'Clientes';

			menu.push(fideicomisos);


			if (this.$auth.can('administrador_de_garantias', 'garantias_lista')) {
				let garantias = {title: 'Activos no productivos'};

				// if (this.$auth.can('administrador_de_garantias', 'garantias_lista'))
					garantias['garantias_activos_garantias'] = 'Garantias para venta';

				menu.push(garantias);
			}

			if (this.$auth.can('administrador_de_garantias','amenidades_lista')) {
				let otros = {title: 'Otros'};

				if (this.$auth.can('administrador_de_garantias','amenidades_lista'))
					otros['garantias_otros_catalogos'] = 'Catálogos';

				if (this.$auth.can('administrador_de_garantias','carga_layout'))
					otros['garantias_layout'] = 'Layouts';

				menu.push(otros);
			}


			this.$store.commit('setMenu',menu);
		}
	}
</script>